import React from 'react';

import SITM from "../images/SITM_artwork.jpg"
import promo_video from "../video/promo_video.mp4"

export const PromoVideo = () => (
  <>
  <div className='promoVideoContainer'>
  <iframe
    className="promoVideo"
    src="https://youtube.com/embed/dGdVqJa7yr8?feature=share"
    ></iframe>
  </div>
  

</>
);
